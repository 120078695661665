<template>
  <div>
     <div class="uasgdk">
        <ul>
          <li class="akdl" v-for="(item,id) in datali" :key="id" @click="onDai(item.id)">
            <div class="akdug">
              <p>{{item.namep}}</p>
              <span>{{item.nameSp}}</span>
            </div>
            <div class="uasda">
              <img :src="item.img" alt="">
            </div>
          </li>
        </ul>
     </div>
  </div>
</template>

<script>
export default {
      data() {
        return {
          datali :[
            {id:1,namep:'每日一叮嚀 | 真正的感恩',nameSp:'慈濟慈善事業基金會',img:require('../assets/img/0.jpg')},
            {id:2,namep:'每日一叮嚀 | 愛的能量 善的祥和',nameSp:'慈濟慈善事業基金會',img:require('../assets/img/001.jpg')},
            {id:3,namep:'每日一叮嚀 | 智慧的教導',nameSp:'慈濟慈善事業基金會',img:require('../assets/img/002.jpg')}
          ]
        }
      },
      methods: {
        onDai(id) {
          this.$router.push({name:'DailyContent',query:{user:id}})
        }
      }
}
</script>

<style lang="less" scoped>
  .uasgdk {
    width: 80%;
    background: #fff;
    margin: 0 auto;
    .akdl {
      position: relative;
      height: 100px;
      border-bottom: 1px solid #f3f0f0;
      p {
        font-size: 18px;
        line-height: 28px;
      }
      span {
        font-size: 14px;
        color:#ccc;
      }
    }
    .akdug {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(-0%, -50%);
    }
    .uasda {
      background: sandybrown;
      width: 64px;
      height: 64px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translate(-0%, -50%);
      img {
        width: 100%;
        height: 100%;
        border-radius: 2px;
      }
    }
  }
  @media screen and (max-width: 450px) {
    .uasgdk {
      width: 100%;
    }
  }
  @media screen and (min-width: 450px) and (max-width: 800px) {
    .homeNeir {
      width: 80%;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 800px) {
    .homeNeir {
      width: 80%;
      margin: 0 auto;
    }
  }
</style>
