import { createStore } from 'vuex'
import io from "socket.io-client";
import config from '../config'
export default createStore({
  state: {
    userData:'', 
    userId:'',
    token:'',
    socket:io(config.apiUrl),         //丢在这里可能不是太合理，后面再看看吧
    serviceSocket:io(config.apiUrl),  //同一个浏览器同时用客服端和用户端会发生数据叠加，再创一个socket连接      
    bgColor:'background: #30bcbc',    //全局背景样式颜色
    textColor:'color : #30bcbc',      
    robot: [
        {
        "sendType" :2,
        "sendPeople" : 'other',
        "message" : "您好，歡迎進入慈濟在線客服諮詢，請點擊您需要諮詢的問題我們會及時給您回覆哦，謝謝！<br>",
        "problem" :["<div class='robotSend'>1.關於我們？</div>","<div class='robotSend'>2.如何領取救助金？</div>","<div class='robotSend'>3.如何諮詢在線客服？</div>"],
        "reply":["慈濟人本着慈悲喜捨之心，予樂拔苦，在人間締造了清新潔淨的慈濟世界。三十多年以來，慈濟共籌集了上百億臺幣的善款，並有四百多萬人或多或少地參與了慈濟的活動。這裏，我們轉載了證嚴上人關於慈善理念，幫助有困難的人度過難關，正是這些對佛法的樸素詮釋和慈濟人的真履實踐，喚起了社會民衆的熱忱。","只需要您和在線客服講解當前您的困難，如當前在線客服未回覆可添加客服line:cjh0011","點擊右上角在綫客服頭像或者左下方對話框中第一個圖標。"]
        }
    
    ],
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
