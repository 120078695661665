<template>
  <div>
    <!-- 頭部 -->
    <div class="titlecss">
      <img class="csslogo" src="../assets/img/logo.png" alt="" style="margin-left: 30px;">
      <span class="titleName">慈濟基金會</span>

      <div class="uadha" @click="toLabor" style="margin-right: 30px;">
        <van-icon name="balance-o" size="40" />
        <p class="sajsgd">點擊此處領取救濟金</p>
      </div>

    </div>
    <!-- 主體 -->
    <div class="ksdh">
      <!-- 轮播头条 -->
      <div>
      <van-notice-bar
        left-icon="volume-o"
        text='陳**領取救濟金10500元。 林**領取救濟金9600元。 黃**領取救濟金11400元。陳**領取補助金10100元。張**領取救濟金8960元。蔡**領取補助金18300元。陳**領取救濟金9880元。
              黃**領取救濟金10400元。  林**領取補助金16000元。李**領取救濟金11500元。張**領取救濟金12000元。王**領取救濟金11900元。蔡**領取救濟金10800元。王**領取補助金16100元。 陳**領取救濟金10500元。 于**領取救濟金9600元。 秦**領取救濟金11400元。簡**領取補助金10100元。張**領取救濟金8960元。蔡**領取補助金18300元。王**領取救濟金9880元。 陸**領取救濟金10400元。  劉**領取補助金16000元。趙**領取救濟金11500元。洪**領取救濟金12000元。王**領取救濟金11900元。蔡**領取救濟金10800元。秦**領取補助金16100元。肖**領取救濟金11500元。平**領取救濟金12000元。王**領取救濟金11800元。蔡**領取救濟金13800元。王**領取補助金12100元。 陳**領取救濟金11500元。'
      />
     </div>
     <!-- 首图 -->
     <div class="automatic">
      <div style="position: fixed; right: 25px;z-index: 999;" @click="toLabor" class="sajsgd1">
        <img style="width: 70px; height: 70px; border-radius: 50%;" src="../assets/images/kefu.jpg" alt="">
        聯係客服
      </div>
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item , id) in lbTu" :key="id">
          <img @click="onLb(item.id)" :src="item.img" alt="">
        </van-swipe-item>
      </van-swipe>
     </div>
     <!-- 塊 -->
     <div class="homeNeir">
      <div class="containers">
      <div class="title">
        <h2>慈濟項目</h2>
     </div>
        <div class="hxx"></div>
        <div class="hss"></div>
     </div>
     <!-- 内容 -->
     <div class="clsneiron">
      <!-- 領取補助金 -->
      <van-row gutter="20">
        <van-col class="clscol" span="12" v-for="(item,id) in jiuzhu" :key="id">
          <img :src="item.img" alt="" @click="onChat">
          <h2 class="clsh3">{{item.name}}</h2>
          <p class="clsp">{{item.msg}}</p>
        </van-col>
     </van-row>
     <!-- 新聞 -->
     <van-row gutter="20">
        <van-col class="clscol" span="12" v-for="(item,id) in neir" :key="id">
          <img :src="item.img" alt="" @click="onParticulars(item.id)">
          <h3 class="clsh3">{{item.name}}</h3>
          <p>{{item.msg}}</p>
        </van-col>
     </van-row>
     </div>
     </div>
    </div>
     <Daily/>
     <Bootm/>
     <!-- <Suspension/> -->

  </div>
</template>

<script>
import Fingerprint2 from 'fingerprintjs2';
import JSEncrypt from 'jsencrypt';
// import ChatGPTWindow from '@/components/ChatGPTWindow.vue';
// import axios from 'axios';
let encryptor = new JSEncrypt();
import Bootm from '@/views/bootm.vue';
// import Suspension from '@/views/Suspension.vue'
import Daily from '@/views/Daily.vue'
export default {
  name:"Home1",
  components:{ Bootm , Daily},
    data() {
       return {
          lbTu:[
            {id:1,img:require('../assets/img/lb1.jpg')},
            {id:2,img:require('../assets/img/lb2.jpg')},
            {id:3,img:require('../assets/img/lb3.jpg')},
          ],
          jiuzhu:[
            {name:'領取補助救濟金',id:1,img: require('../assets/img/lingqian.jpg'),msg:'扶贫济困，扶助弱勢，捐助貧困家庭，符合條件領取補助金。'},
            {name:'領取義工補助金',id:2,img: require('../assets/img/yigon.jpg'),msg:'加入義工完成義工認證即可領取義工補助金。'},],
          neir:[
            {name:'醫療援助與健康促進',id:1,img: require('../assets/img/yiliao.jpg'),msg:''},
            {name:'助學教育',id:2,img: require('../assets/img/zhuxue.jpg'),msg:''},
            {name:'長期濟住',id:3,img: require('../assets/img/jizhu.jpg'),msg:''},
            {name:'寒冬送暖',id:4,img: require('../assets/img/handon.jpg'),msg:''}
          ],

          isDisplay: true,
            bgColor: '#30bcbc',
            socket: this.$store.state.socket,
            user: {
                userId: '',
                userName: '',
                userState: 0,
                isService: 0,
                isProhibit: 0
            },
            socketRoom: '',
            message: '',
            messageList: [],
            sendData: '',
            maxTokens: 2048,
            allowSession: false,
            showPopup: false,
            isStream: true,
            netCount: 0,
            footHit: '請輸入...',
            oldSendData: '',
            speed: 110,
            loadLoop: function () { },
            moreToolList: [
                {
                    name: "留言",
                    image: require("../assets/images/kefu.jpg"),
                },
            ],
       }
    },
    mounted(){
      this.initialization();
        //错误通知返回
        this.socket.on("error", (data) => {
            this.$toast(data[0].message);
        });
        //访问注册
        this.socket.on("visitReturn", (data) => {
            this.user = JSON.parse(data[0].data)
        });
        //连接客服成功通知
        this.socket.on("linkServiceSuccess", (data) => {
            this.socketRoom = data[0].data.socketRoom;
            this.$toast(data[0].data.serviceName + "爲您服務");
            //数据存储到localStorage
            this.user.socketRoom = this.socketRoom;
            this.user.receiveId = data[0].data.receiveId;
            localStorage.setItem('userData', JSON.stringify(this.user));
            //设置vuex
            this.$store.state.userData = this.user;
            //页面跳转
            this.$router.push({ path: '/customerChat', replace: false })
        });
    },
    methods: {
      // onChat () {
      //   // this.$router.push({ name: 'Home'})
      //   this.$router.push({ path: '/customerChat', replace: false })
      // },
      onParticulars (id) { // 新聞
        console.log(id)
        this.$router.push({path: '/News',query: {user: id}})
      },
      onLb (id) { //轮播详情
      console.log(id)
        this.$router.push({name:'LbNews',query: { user: id } })
      },

      initialization() {
            //获取浏览器指纹并发送初始数据
            Fingerprint2.get((components) => {
                const values = components.map(function (component, index) {
                    if (index === 0) { //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
                        return component.value.replace(/\bNetType\/\w+\b/, '')
                    }
                    return component.value
                })
                // 生成最终浏览器指纹
                const murmur = Fingerprint2.x64hash128(values.join(''), 31);
                this.user.userId = murmur;
                localStorage.setItem('userId', murmur);
                this.user.userName = "用戶" + murmur.slice(0, 6);
                this.socket.emit("visit", this.user);
            })

            this.socket.emit("getPublicKey");
            //接收公钥
            this.socket.on("returnPublicKey", (data) => {
                let publicKey = JSON.stringify(data[0].data).replace(/\\r\\n/g, '');
                encryptor.setPublicKey(publicKey);
            });

            this.messageList.push(this.$store.state.robot[0])

        },

            //转人工
            toLabor() {
            this.socket.emit("toLabor", this.user);
        },
  }
}
</script>

<style lang="less" scoped>
  .titlecss {
    width: 100%;
    height: 70px;
    background: #fff;
    position: relative;
    text-align: center;
    // line-height: 56px;
    position: fixed;
    z-index: 999;
  }
  .csslogo{
    height: 100%;
    position: absolute;
    left: 0;
  }
  .titleName {
    font-weight: 900;
    font-size: 26px;
    color: #ff976a;
    line-height: 70px;
  }
  .uadha {
    position: absolute;
    right: 6px;
    top: 8px;
    // width: 80px;
    cursor: pointer;
  }

    .sajsgd {
      font-weight: 700;
      font-size: 18px;
      background-image: linear-gradient(60deg, #0eece8, #150ed7);
      background-clip: text;
      color: transparent;
      // color: #ff976a;
    }
    .sajsgd1 {
      background-image: linear-gradient(90deg, #f00606, #05e7c5);
      background-clip: text;
      color: transparent;
    }
  .ksdh {
    padding-top: 70px;
  }
 .notice-swipe {
    height: 40px;
    line-height: 0px;
  }
   .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #39a9ed;
  }
  .automatic {
   img {
    display: block;
    width: 100%;
    height: auto;
   }
  }
  .containers {
    margin: 20px auto;
    .title {
    width: 260px;
    margin: 0 auto;
    text-align: center;
    h2 {
      font-family: "Lucida Calligraphy", cursive, serif, sans-serif;
      color: #6e6476;
      font-size: 40px;
      font-weight: 900;
    }
   }
    .hxx {
    margin: 0 auto;
    width: 214px;
    height: 1px;
    background: #ccc;
    }
    .hss {
    margin: 0 auto;
    width: 66px;
    height: 2px;
    background: #3b9ef2;
    }
  }
  .clsneiron {
    margin: 0 20px;
    .clscol {
      margin: 10px 0;
    }
    img {
      width: 100%;
      cursor: pointer;
      transition: all 1.5s;
      border-radius: 5px
    }
    h2 {
      font-weight: bold;
      margin: 6px 0;
      text-align: center;
      color: #474747;
    }
    h3 {
      font-weight: bold;
      margin: 20px 0;
      text-align: center;
      color: #474747;
    }
    .clsp {
      color: #878383;
      padding: 0 10px;
      text-align: center;
    }
  }
  @media screen and (max-width: 450px) {

  }
  @media screen and (min-width: 450px) and (max-width: 800px) {
    .homeNeir {
      width: 80%;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 800px) {
    .homeNeir {
      width: 80%;
      margin: 0 auto;
    }
  }
</style>
