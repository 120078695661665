<template>
  <!-- 赞助商 -->
  <div>
     <div class="clsSponsor">
        <div class="clsBack">
           <p>贊助商鏈接</p>
           <van-grid :border="false" direction="horizontal" :column-num="3">
            <van-grid-item v-for="(item , id) in zhanzhu" :key="id" text="文字" style="color: rgb(43, 68, 151);font-size: 20px;">
              <a :href="item.url" target="_blank"><img :src="item.img" alt=""></a>
              {{ id == 5 ? "maicoin" : '' }}
           </van-grid-item>
          </van-grid>
        </div>
     </div>
     <!-- 商號 -->
      <div class="record hidden-xs">
            <span>
                Copyright ©2009 Internet service by 台灣網站導航 ~ Tzu Chi Foundation
            </span>
     </div>

   </div>
</template>

<script>

export default {

    data() {
      return {
        zhanzhu:[
            {name:'臺北慈濟醫院',id:'1',url:'https://taipei.tzuchi.com.tw/',img:require('../assets/img/logo1.png')},
            {name:'關山慈濟醫院',id:'2',url:'https://kuanshan.tzuchi.com.tw/',img:require('../assets/img/logo2.png')},
            {name:'慈濟科技大學',id:'3',url:'https://www.tcust.edu.tw/index.php',img:require('../assets/img/logo3.png')},
            {name:'慈濟大學',id:'4',url:'https://www.tcu.edu.tw/',img:require('../assets/img/logo4.png')},
            {name:'大愛網路電臺',id:'5',url:'http://daairadio.tw/Page/Home/Index.aspx',img:require('../assets/img/logo5.png')},
            {name:'MAX',id:'6',url:'https://max.maicoin.com/',img:require('../assets/img/logo6.png')},
          ]
      }
    }
}
</script>

<style lang="less" scoped>
.clsSponsor {
  margin-top: 10px;
    .clsBack {
      p {
          font-family: "Lucida Calligraphy", cursive, serif, sans-serif;
          color: #6e6476;
          font-size: 20px;
          font-weight: 900;
          text-align: center;
        }
        img {
          width: 88%;
          height: 33px;
        }
      }
    }
  .record  {
    text-align: center;
    height: 30px;
    line-height: 30px;
    background: #222;
    color: #fff;
    }
    @media screen and (max-width: 450px) {
  .clsSponsor {
    .clsBack {
      width: 100%;
      margin: 0 auto;
    }
  }
}
@media screen and (min-width: 450px) and (max-width: 800px) {
  .clsSponsor {
    .clsBack {
      width: 50%;
      margin: 0 auto;
    }
}
}
@media screen and (min-width: 800px) {
  .clsSponsor {
   .clsBack {
      width: 50%;
      margin: 0 auto;
    }
}
}
</style>
