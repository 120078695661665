<template>
  <div>
     <div class="titlereturn">
      <van-icon style="cursor:pointer" @click="onReturn" class="clsLeft" name="arrow-left" >返回</van-icon>
      <span class="titleName">慈济慈善事业基金会</span>
       <div>
     </div>
    </div>
    <!-- 基金会内容 -->
    <div v-if="userId == '1'" class="clsNews">
      <div class="kjah">
        <div class="ajsk">
        <img src="../assets/img/640.jpg" alt="">
        <p>
          2023年4月11日，慈濟慈善事業基金會第三屆第十一次理事會在蘇州慈濟志業園區圓滿舉行。中央統戰部十一局副局長吳國生、中央統戰部十二局一級調研員顏玉科、江蘇省民族宗教事務委員會民族處處長劉克元，慈濟基金會副理事長王端正、祕書長林碧玉等理監事參會。
        </p>
        </div>
      </div>
      <div class="kjah">
        <div class="ajsk">
        <img src="../assets/img/641.jpg" alt="">
        <p>
          王端正副理事長（上圖右）代表理事長證嚴法師致辭，回溯過去三十二年，自1991年華東水災起，證嚴法師用愛搭起了兩岸橋樑，始終秉持初心，不敢有負使命。王端正副理事長表示，慈濟人走遍大江南北，無論精準脫貧、鄉村振興、共同富裕，只要能夠爲需要的人服務，再多的辛苦都無悔無憾。
        </p>
        <p>
          “爲鄉村造血，爲城鄉搭橋；爲國家育才，爲老弱送暖；爲生態供氧，爲急難馳援。”過去如此，未來亦復如此。王副理事長期許，要把慈善工作做好、做實、做精、做準、做穩、做強。
        </p>
        </div>
      </div>
      <div class="kjah">
        <div class="ajsk">
        <img src="../assets/img/642.jpg" alt="">
        <p>
          林碧玉祕書長（上圖右二）深有感觸地表示2020年從福建順昌上鳳慈濟大愛村出來，就傳出了新冠肺炎疫情。我們多渠道採購防疫物資，送到有需要的地方。期間很多的艱辛、不容易，大家都因着愛而一一克服。延續王副理事長回首過去三十二年，林碧玉祕書長將心路歷程總結成爲：
        </p>
        </div>
      </div>
      <div class="clsShi">
        <section style="text-align: center;box-sizing: border-box;" powered-by="xiumi.us"><p style="margin: 0px;padding: 0px;box-sizing: border-box;">三十二載漫漫神州路</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">跋山涉水步步不驚心</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">烈日嚴冬大江南北行</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">救災歷險生命一線間</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">無畏艱困窮鄉及僻壤</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">不曾思惟爲己求安樂</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">分秒但爲苦難衆生行</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">穿梭兩岸用愛忙搭橋</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">用力育才助學逾十萬</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">青年學成返鄉助脫貧</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">新冠病毒無聲擾全球</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">證嚴法師聲聲安身心</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">志工領受奮力抗疫行</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">夜以繼日機構小區間</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">助力脫貧三區與三洲</p><p style="margin: 0px;padding: 0px;box-sizing: border-box;">共同富裕精準創和諧</p></section>
      </div>
      <div class="kjah">
        <div class="ajsk">
          <p>
          林碧玉祕書長強調，理事長證嚴法師在2023年給大家的祝福語“慈悲行善福滿門，智慧處事德傳家”。希望不忘初心、不負使命，用愛搭橋，永續兩岸情誼。
        </p>
        <img src="../assets/img/644.jpg" alt="">
        <p>
          隨後，基金會項目處和財務處就2018至2022五年工作重點、2022年工作重點及2022年財務決算作報告。
        </p>
        <img src="../assets/img/645.jpg" alt="">
        </div>
      </div>
      <div class="kjah">
        <div class="ajsk">
          <p>
          對於年度工作報告、財務報告、決算案，諸位理事一致通過。
        </p>
        <img src="../assets/img/646.png" alt="">
        <p>
          中央統戰部十一局副局長吳國生（上圖左）代表統戰部十一、十二局表示，慈濟助力脫貧攻堅、緊跟政府戰略決策，讓受助者很感動，慈濟付出了很多努力，也得到社會認可，慈濟的整體運行規範有序。同時，吳國生監事也提出四個希望和一個期許，他期許慈濟能助力兩岸交流，把大愛情懷延續下去。
        </p>
        <img src="../assets/img/647.jpg" alt="">
        </div>
      </div>
      <p class="asdk">
          王端正副理事長談到疫情前舉辦的兩岸青年文化交流，期待不久的將來可以重啓兩岸青年交流，因爲國家的希望在青年的身上。我們絕對不會辜負慈濟創始人證嚴法師的期許——用愛搭起兩岸的橋樑。
        </p>
    </div>
    <!-- 修建小学 -->
    <div v-if="userId == '2'" class="clsNews">
       <div class="hialk">
        <div class="ajsk">
        <img src="../assets/img/xj1.jpg" alt="">
        <p>
          工人師傅李勇邊吃飯邊紅着眼眶，他說幹了十幾年工人，從來沒有像現在這樣被人尊重、被愛包圍：“十年樹木百年樹人，我們一定保證工程質量，盡全力地給它蓋好，‍‍讓學生早早入校，因爲這個也是我們對學生最好的幫助！”‍‍
        </p>
        <div class="kliuhb"></div>
        </div>
      </div>
      <div class="sadad">
        <p class="fialk"> 天氣驟變，從“晚春”到“深秋”</p>
      </div>
      <div class="hialk">
        <div class="ajsk">
        <p>
          慈濟援建的河南焦作修武縣慈濟小學項目如火如荼進行着，截至4月21日，已完成教學樓北段一層頂板面層鋼筋綁紮、風雨操場1層模板施工、宿舍樓一層頂板模板支設。
        </p>
        <img src="../assets/img/xj2.jpg" alt="">
        <p>
          4月22日，從30度的“晚春”到10度的“深秋”，河南天氣像坐上了過山車，開啓了“滿30減20”的冷凍模式。天空還淅淅瀝瀝下着雨，地面因雨水的滋潤，變得泥濘不堪。也因天氣驟變，給了工人們喘息休息的時間，慈濟自開工儀式後的第2次工地關懷也如期而至。
        </p>
        <img src="../assets/img/xj3.jpg" alt="">
        <p>
          來自臺北、臺東、高雄、臺中，桃園等地慈濟志工從前一天開始陸續抵達，到工地生活區，打掃衛生、整理場地、修理水管、籌備食材，爲第二天活動做準備工作。
        </p>
        <img src="../assets/img/xj4.jpg" alt="">
        <p>
          愛出者愛返。“7·20”水災中，郜屯村受災嚴重，慈濟志工及時送來了生活物資，村民從此與慈濟結下情誼；正在修建的慈濟小學更是解決了當地孩子上學難、上學遠的難題。這天，村書記韓豔紅和主任貟更臣帶領村中居民前來付出。
        </p>
        </div>
      </div>
      <div class="sadad">
        <p class="fialk"> 1小時  從滿地灰塵到一塵不染</p>
      </div>
      <div class="hialk">
        <div class="ajsk">
        <p>
          目前工地在建工人將近80名，食堂太小，室外又在下雨。志工不得不另尋一處場地作爲臨時餐廳。
        </p>
        <p>
          恰巧工人的住宿樓內有個長年未啓用的室內羽毛球場，可以同時放下十五六張大圓桌，可供150多人用餐。
        </p>
        <p>
          徵得同意後，爲使工人有個舒心、溫暖的就餐環境，志工找來清掃工具，與郜屯村的村民、工地工人、附近的熱心羣衆一起將羽毛球場內的東西進行整理，對環境進行大掃除。
        </p>
        <img src="../assets/img/xj5.jpg" alt="">
        <p>
          6歲的女孩侯可優也加入到清掃的行列中，稚嫩的笑臉、手拿拖把的模樣乖巧可愛。她的媽媽柴嬌莉說：“我和孩子爸爸都在工地幹活，你們的行動感染了孩子，我們要向你們學習。以後多做好事，用自身行動去教育孩子。”
        </p>
        <img src="../assets/img/xj6.jpg" alt="">
        <p>
          志願者田小可的孩子田嘉佑，今年5歲了，看到大人們熱火朝天的大掃除，他也有樣學樣地拿起拖把，認真地拖着地，不知疲勞，甚是可愛。
        </p>
        <img src="../assets/img/xj7.jpg" alt="">
        <p>
          連日的雨天，使得羽毛球場門口的道路變得泥濘溼滑，從臺階跳到泥路上極易摔倒，志工就地取材，臨時搭建“愛心橋”。
        </p>
        <img src="../assets/img/xj8.jpg" alt="">
        <p>
          一個小時後，髒亂不堪的羽毛球場和工友住處的走廊都煥然一新，球場內綠色地板顯露出原有的底色。
        </p>
        <img src="../assets/img/xj9.jpg" alt="">
        <img src="../assets/img/xj10.jpg" alt="">
        </div>
      </div>
      <div class="sadad">
        <p class="fialk"> 愛心傳遞  保證工程質量</p>
      </div>
      <div class="hialk">
        <div class="ajsk">
        <img src="../assets/img/xj11.jpg" alt="">
        <p>
          廚房內，煙霧繚繞，承擔製作午餐的志工平均都六十多歲了，早上6點就來到工地準備，歡喜地付出，一刻不停。
        </p>
        <img src="../assets/img/xj12.jpg" alt="">
        <p>
          另一邊志工冒着雨，接力搬運餐桌和凳子進入球場大廳。不一會兒，香蕉、橘子、小黃瓜等特色水果已擺滿一張張長條桌。十來張大圓桌上也鋪上了紅紅的桌布，讓這寒冷的“倒春寒”增添了幾許溫暖和喜慶。
        </p>
         <img src="../assets/img/xj13.jpg" alt="">
          <img src="../assets/img/xj14.jpg" alt="">
        <p>
          志工交替陪伴工人們紛紛落座，向大家介紹慈濟慈善足跡，帶動大家比手語《認識你真好》，一時間，大廳內歡聲笑語，彼此的距離悄然靠近，感受着愛與關懷。
        </p>
        <img src="../assets/img/xj15.jpg" alt="">
        <p>
          志工蔣萌向工人們講述修武縣慈濟小學的援建因緣。他說這所小學凝聚了太多人的愛和期望。
        </p>
        <img src="../assets/img/xj16.jpg" alt="">
        <p>
          一直忙前忙後，協調餐桌和餐椅的郜屯村主任貟更臣（下圖左）表示看到志工的付出，心中只有兩個詞：感動、興奮。他說：“慈濟小學‍‍在這幾個村屬於核心地區，9月1號這所學校就要開學啓用了。每天我經過人民路，都能看到工程進度，心裏非常感動。”
        </p>
        <img src="../assets/img/xj17.jpg" alt="">
        <img src="../assets/img/xj18.jpg" alt="">
        <p>
          11點，炸蓮菜、乾煸豆角、豆角平菇、孜然土豆等12道菜，冒着香噴噴的熱氣陸續就位。志工排成列隊，微笑着將飯菜盛入工人們的碗中。工人們端着“小山”似的飯菜回到座位，大快朵頤。
        </p>
        <img src="../assets/img/xj19.jpg" alt="">
        <p>
          木工師傅李大山說：“今天吃了12道菜，還喝了兩碗湯，很豐富很溫暖。”李大山回憶起第一次慈濟工地人文關懷時，他收到的毛巾、花露水等物資，“非常實用，幫助我們解決了很多實際困難，我們‍‍能做的就是保證工程質量，好好幹活。”
        </p>
        <img src="../assets/img/xj20.jpg" alt="">
        <p>
          龐海榮便是的志願者之一。幾年前，她的孩子就讀於張弓鋪小學，當時窗戶漏風、房頂漏雨，後跟隨政策去往十幾公里之外的周莊小學就讀，大人孩子都很不方便。
        </p>
        <p>
          她說，修武縣慈濟小學的建設，對村民們是個重大利好消息。“村裏很多年輕人不能工作，‍‍必須得在家接送孩子，交給老人擔心雨天路滑不安全。真的特別感謝你們，‍‍解決了老百姓的大難題。”
        </p>
        </div>
      </div>
      <div class="askdb">
        （文:程冰冰 凌梅 楊小峯 圖:馬慧娟 劉躍蕊 2023年4月慈濟新聞報道）
      </div>
    </div>
    <!-- 多幸运 我有个“我们” -->
    <div v-if="userId == '3'" class="clsNews">
       <div class="hialk">
        <div class="ajsk">
        <img src="../assets/img/xy1.png" alt="">
        <p>
          在這木棉花、紫荊花競相綻放，草長鶯飛的季節裏，信宜籍的大學助學生們時隔兩年，再次回到慈濟台灣的家，圍坐一起包餃子，享團圓，感受大家庭的愛與溫暖。
        </p>
        <div class="kliuhb"></div>
        </div>
      </div>
      <div class="sadad">
        <p class="fialk"> 包饺子 享团圆</p>
      </div>
      <div class="hialk">
        <div class="ajsk">
        <p>
          早上7點，志工已經洗洗切切，備了10種蔬菜，製作出3種美味的水餃餡料。負責準備食材的志工廖雙說：“孩子們兩年沒有回‘家’了，準備各式各樣的蔬菜，營養更全面，口感也會更豐富。”
        </p>
        <img src="../assets/img/xy2.jpg" alt="">
        <p>
          “這些年，師姑師伯給我的愛一直記在心裏，我要把它包成餃子，皮爲愛，餡是濃濃的親情，這次像久別在外的遊子回到了家，特別開心、激動。”楊同學邊包餃子，邊跟志工說着兩年來的想念。
        </p>
        <img src="../assets/img/xy3.png" alt="">
        <p>
         爲了成爲第一個衝到“家”的人，李同學一早就乘車趕來。聽到熟悉的歡迎歌，看到熟悉的面孔，感受着熟悉的氛圍，他在志工手把手的教導下，學着包餃子。
        </p>
        <img src="../assets/img/xy4.png" alt="">
        <p>
          小婷和蓉蓉來自同一所大學，兩人相邀而來，相互分享，要包出更漂亮的餃子。38斤的餃子皮，在同學們和志工的巧手下，包出了2200多隻餃子。出。
        </p>
        <img src="../assets/img/xy5.png" alt="">
        <p>
          看着同學們吃得津津有味，廖雙眉開眼笑地說：“孩子們吃得歡喜，也是我們作爲‘爸爸媽媽’最開心的事情。”
        </p>
        <img src="../assets/img/xy6.png" alt="">
        </div>
      </div>
      <div class="sadad">
        <p class="fialk">滴水匯聚成江海</p>
      </div>
      <div class="hialk">
        <div class="ajsk">
        <p>
          一張張熟悉的笑容，一個個活潑的身影從志工祁海東身旁經過，他感慨萬千。像大多數志工一樣，不知從何時起就憧憬着這次的相聚。
        </p>
        <img src="../assets/img/xy7.png" alt="">
        <p>
          他說，疫情期間，我們只能到學校門口或在環保站、在家裏，通過視頻或保持適當距離和孩子們互動、交流。如今孩子們終於可以回到慈濟的家，感受家的溫馨，志工的關愛。
        </p>
        <p>
          “師姑們太熱情了，第一次來的學弟受寵若驚，像極了當初的我。慈濟慈善的故事，詮釋着仁愛的理念，在慈濟幫助我的四年間，這裏循環上演着很多有愛的畫面。”黃同學跟志工樑育蓮說。
        </p>
        <img src="../assets/img/xy8.png" alt="">
        <p>
          黃同學畢業後準備去臺北工作，樑育蓮告訴他，深圳也有慈濟的家，歡迎常回家看看。”
        </p>
        <p>
          溫馨的互動在不同的空間上演。孩子們向志工王麗英訴說着自己學習中的困難，王麗英爲他們一一開導。
        </p>
        <p>
          講述疫情期間，醫學生面對高強度的工作，承受很大的心理壓力，爲守護全民健康，努力調節自身心理狀態，隨着春暖花開，回想這些經歷，感受更多的是成長的快樂。
        </p>
        <img src="../assets/img/xy9.png" alt="">
        <p>
          志工劉麗娟、彭麗芬陪伴陳同學參觀慈濟的家，導覽海報，介紹慈濟慈善足跡。陳同學感慨，人人懷有一縷善念，付出一點善意，做出一個善舉，便是種下一枚枚善的種子，假以時日，便可聚成大愛，就如滴水匯聚成江海。
        </p>
        <img src="../assets/img/xy10.png" alt="">
        <p>
          “雖然現在我還是名學生，做不到多大的善行，但日常生活中亦能多幫助別人，多用環保製品，多做一些志願服務，這也是對社會有益的善行。”陳同學說。
        </p>
        </div>
      </div>
      <div class="sadad">
        <p class="fialk">多幸運 我有個我們</p>
      </div>
      <div class="hialk">
        <div class="ajsk">
        <p>
          志工搭起愛的拱橋，就像在遮風擋雨，呵護幼苗成長，同學們踏歌而行，步入活動會場。
        </p>
        <img src="../assets/img/xy11.png" alt="">
        <p>
          視頻《2022年信慈濟大學生助學剪影》記錄着臺中各地誌工陪伴同學舉辦生日會、參訪科技院所、郊遊踏青，同過端午、慶中秋，送防疫物資；一起做環保、參加慈善活動等。
        </p>
         <img src="../assets/img/xy12.png" alt="">
        <p>
          一幅幅感人的畫面，帶着同學們回顧過往點點滴滴，一股股暖流流淌在身體裏。志工何燕萍分享，自己是2009年慈濟在臺中第一屆資助的學生，2014年大學畢業後，她成爲一名慈濟志工，開始利用業餘時間走進他人的生命中。
        </p>
        <p>
          何燕萍說：“每個人都可以做小事行大善。我們走過的路，付出的愛，都會爲自己的生命留下深刻的痕跡，爲心靈畫下豐富的版圖。
        </p>
        <p>
          小嫺高三暑期加入慈濟大家庭，如今已4年過去了。她分享：“大一時，我來過這裏參加活動，跟師姑師伯們做環保，後來疫情原因，我們不能出校，但師姑師伯經常會去學校看望我們，在中秋節的時候給我們送月餅，在冬天的時候給我們送熱湯。
        </p>
          <img src="../assets/img/xy13.png" alt="">
        <p>
          如今的小嫺在學校看到摔倒的自行車，會一輛輛扶起擺放整齊；在地鐵裏，看到一位女生低血糖暈倒，她也會及時幫忙。
        </p>
        <p>
          “金花師姑說，每一個人都可以成爲別人生命中的擺渡人，雖然我們可能沒有特別大的能力，但是，我們願意用最大的努力去做，這是我在慈濟裏面學到的最大收穫。
        </p>
        <p>
          源於一念心，志工葉威生不忍家鄉困難家庭的孩子沒書讀，從2009年牽起信宜中學助學的情誼，2012年開啓信宜籍大學生助學。
        </p>
        <img src="../assets/img/xy14.png" alt="">
        <p>
          他說：“14年的助學路，漫長而幸福，在這世上，有什麼比幫助別人，讓別人充滿希望更有意義呢？助學，讓孩子們重燃了未來的希望。”
        </p>
        <p>
          婷婷分享：“今天，聽到歌曲《這世界那麼多人》，歌詞唱到了我們的心裏面，‘這世界有那麼多人，多幸運，我有個我們。’感謝大家，我們接受到師姑師伯們的呵護，將來我們長大了，也會成爲獨當一面的強者，去幫助其他人，把愛傳遞下去。”
        </p>
        <img src="../assets/img/xy15.png" alt="">
        </div>
      </div>
      <div class="askdb">
        （文:陆梦迪 图:曾海涛 关国新 周旭晖 2023年5月台灣大愛廣播报道）
      </div>
    </div>
    <Bootm/>
  </div>
</template>

<script>
import Bootm from '@/views/bootm.vue';
export default {
  components:{ Bootm },
   data() {
    return {
      userId:''
    }
   },
   created() {
    this.userId = this.$route.query.user
    console.log(this.userId,3333);
   },
   methods: {
    onReturn() {
      this.$router.push({name:'Home1'})
    }
   }

}
</script>

<style lang="less">
.titlereturn {
  width: 100%;
  // height: 56px;
  background: #fff;
  position: relative;
  text-align: center;
  line-height: 56px;
  margin-bottom: 12px;
  .clsLeft {
  font-size: 22px;
  line-height: 56px;
  position: absolute;
  left: 0;
  color: #6f6e6e;
}
.titleName {
  font-weight: 900;
  font-size: 16px;
  color: #ff976a;
}
}
 .kjah {
  width: 88%;
  margin: 6px auto;
  border: 1px solid #3f4edb;
  border-radius: 2%;
  padding: 4px;
  box-shadow: 3px 1px 11px 0px rgba(0, 0, 0, 0.9);
  .ajsk {
  height: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 1%;
  img {
    width: 100%;
    border-radius: 4px;
  }
  p {
    font-size: 18px;
    color: #444343;
    line-height: 26px;
    margin: 24px 6px;
  }
  }
 }
 .clsShi {
  font-family: "Lucida Calligraphy", cursive, serif, sans-serif;
  line-height: 24px;
  font-size: 18px;
 }
 .asdk {
    font-size: 18px;
    color: #444343;
    line-height: 26px;
    margin: 40px;
    text-align: center;
 }

//  修建小学
 .hialk {
  width: 88%;
  margin: 6px auto;
  border: 1px solid #ccc;
  background: #fff9f6;;
  padding: 4px;
  box-shadow: 3px 1px 11px 0px rgba(0, 0, 0, 0.9);
  .ajsk {
  height: 100%;
  border: 1px solid #e4e4e4;
  border-radius: 1%;
  img {
    width: 100%;
    border-radius: 4px;
  }
  p {
    font-size: 18px;
    color: #444343;
    line-height: 26px;
    margin: 24px 6px;
  }
  .kliuhb {
    width: 100%;
    height: 32px;
    background:#bb7742 ;
    border-radius: 0 0 6px 6px;
  }
  }
 }
 .sadad {
  width: 88%;
  margin: 30px auto;
 }
 .fialk {
    background: #bc9143;
    font-size: 22px;
    color: #fff;
    background: rgb(188, 145, 67);;
  }
  .askdb {
    text-align: center;
    line-height: 50px;
  }
 @media screen and (max-width: 450px) {

  }
  @media screen and (min-width: 450px) and (max-width: 800px) {
    .clsNews {
      width: 60%;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 800px) {
    .clsNews {
      width: 60%;
      margin: 0 auto;
    }
  }
</style>
