import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import comment from '../views/Comment.vue'
import customerChat from '../views/customerChat.vue'
import customerServiceLogin from '../views/customerServiceLogin.vue'
import customerService from '../views/customerService.vue'
//主页
import Home1 from '../views/Home1.vue'
import News from '../views/News.vue'
import LbNews from '../views/Lbnews.vue'
import DailyContent from '../views/DailyContent.vue'
const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/comment',
    name: 'comment',
    component: comment
  },
  {
    path: '/customerChat',
    name: 'customerChat',
    component: customerChat,
    meta: {
      requireAuth: true      //开启权限
    }
  },
  {
    path: '/customerServiceLogin',
    name: 'customerServiceLogin',
    component: customerServiceLogin
  },
  {
    path: '/customerService',
    name: 'customerService',
    component: customerService,
    meta: {
      requireAuth: true      //开启权限
    }
  },
  //主页
  {
    path: '/',
    name: 'Home1',
    component: Home1
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/LbNews',
    name: 'LbNews',
    component: LbNews
  },
  {
    path: '/DailyContent',
    name: 'DailyContent',
    component: DailyContent
  }

]

const router = createRouter({
   history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
