<template>
<router-view></router-view>
</template>

<style lang="scss">
body {
  margin: 0;
  // background-color: hsla(0,0%,98%,.8);
  background: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  font-family: "Arial","Microsoft YaHei","黑体","宋体",sans-serif
 }
</style>
