<template>
    <div class="infoBox">
        <!--工具栏-->
        <div class="serviceTool">
            <div v-for="(item, index) in serviceTool" :key="index" :class="[
                'service_tool',
                item.id == current_state ? 'active_tool' : '',
            ]" v-on:click="changeToolPage(item)">
                {{ item.text }}
            </div>
        </div>
        <!--用户信息-->
        <div v-show="current_state == 1" class="infoContent">
            <div>
                <label>用户id：</label>
                <span>{{ user.data.userId }}</span>
            </div>
            <div>
                <label>用户名：</label>
                <span>{{ user.data.userName }}</span>
            </div>
            <div>
                <label>临时房间号：</label>
                <span>{{ user.data.socketRoom }}</span>
            </div>
            <div>
                <label>临时服务id：</label>
                <span>{{ user.data.receiveId }}</span>
            </div>
            <div>
                <label>用户状态：</label>
                <span>【{{ user.data.isProhibit}}】</span>
            </div>
        </div>
        <!--快捷回复-->
        <div v-show="current_state == 2" class="infoContent" style="display: block">
            <template :key="index" v-for="(item, index) in fastReplay">
                <ul class="domtree">
                    <li>
                        <p class="tree-title" v-on:click="selectEasy(item.id)">
                            {{ item.title }}
                        </p>
                        <ul v-show="currentEasy == item.id" style="position: relative">
                            <li v-for="(son, index2) in item.sonItem" :key="index2">
                                <p class="sonTitle" :title="son.title" v-bind:data-id="son.id"
                                    v-on:click="selectReplay(son.title)">
                                    {{ son.title }}
                                </p>
                                <div class="see_all" name="see_all" v-bind:data-id="son.id">
                                    {{ son.title }}
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </template>
        </div>
        <!--对接页面-->
        <div v-show="current_state == 3" class="infoContent">
            <iframe style="width: 100%; height: 100%; border: 0px" src="http://60.205.187.0/home/zfb/pay.html"></iframe>
        </div>
    </div>
    
</template>

<script>
export default {
    name: 'ServiceRightPage',
    props: {
        user:Object
    },
    methods: {
        selectReplay(text) {
            this.$emit('submit', text);
        },

        sendMessage(data, type) {
            this.$emit('sendMessage', data, type);
        },
        //切换客服工具
        changeToolPage(serviceTool) {
            this.serviceTool.map((a) => {
                if (a.id == serviceTool.id) {
                    a.state = true;
                    this.current_state = a.id;
                } else {
                    a.state = false;
                }
            });
        },
        //切换快捷回复
        selectEasy(id) {
            if (id == this.currentEasy) {
                this.currentEasy = 0;
            } else {
                this.currentEasy = id;
            }
        },

    },
    data() {
        return {
            current_state: 1,
            currentEasy: 0,
            serviceTool: [
                {
                    id: 1,
                    text: "客戶信息",
                    state: true,
                },
                {
                    id: 2,
                    text: "快捷回復",
                    state: false,
                },
                // {
                //     id: 3,
                //     text: "慈濟客服",
                //     state: false,
                // },
            ],
            fastReplay: [
                {
                    "title": "快捷回復",
                    "sonItem": [
                        {
                            "title": "您好 有什麼可以幫助您",
                            "id": 10001
                        },
                        {
                            "title": "您好 您是來參加慈濟補助/救助現金活動嗎?",
                            "id": 10001
                        },
                        {
                            "title": "參加補助/救助需要滿足18歲以上。",
                            "id": 10001
                        },
                        {
                            "title": "我請提供您的姓名年齡和職業。",
                            "id": 10001
                        },
                        {
                            "title": "救助活動是根據您家庭情況一次性領取。",
                            "id": 10001
                        },
                        {
                            "title": "補助活動是以您做義工小時數進行補貼。",
                            "id": 10001
                        },
                        {
                            "title": "您可以添加客服line:cjh0011咨詢。",
                            "id": 10001
                        },
                    ],
                    "id": 10000
                },
                // {
                //     "title": "假ikun",
                //     "sonItem": [
                //         {
                //             "title": "小黑子，食不食油饼？",
                //             "id": 20001
                //         },
                //         {
                //             "title": "小黑子，苏珊",
                //             "id": 20001
                //         }
                //     ],
                //     "id": 20000
                // },
                // {
                //     "title": "纯路人",
                //     "sonItem": [
                //         {
                //             "title": "纯路人，感觉没必要",
                //             "id": 30001
                //         },
                //         {
                //             "title": "纯路人，真觉得你们有没有树枝，要是你们也被这样子黑的话，你们会怎么样？",
                //             "id": 30001
                //         },
                //     ],
                //     "id": 30000
                // }
            ],

        }
    },
}
</script>
<style scoped>
@import url("../assets/css/ServiceRightPage.css");
</style>

