<template>
  <div>
    <div class="titlereturn">
      <van-icon style="cursor:pointer" @click="onReturn" class="clsLeft" name="arrow-left" >返回</van-icon>
      <span class="titleName">慈济慈善事业基金会</span>
       <div>
     </div>
    </div>
    <div v-if="userId == '1'" class="clsNews">
      <p class="alkdsj">每日一叮嚀 | 真正的感恩</p>
      <p class="okadsk">Tzu Chi 慈濟慈善事業基金會 2023-05-18 15:42 發表於桃園</p>
      <div class="oiash">
        <img src="../assets/img/01.jpg" alt="">
      </div>
      <p class="jasdkj">真正的感恩</p>
      <div class="aisudh">
      <p>真正的感恩，</p>
      <p>是經歷人生酸甜苦辣後，</p>
      <p>化成甘甜的真滋味；</p>
      <p>猶如走過種種考驗、</p>
      <p>難關後，長養出豁達的胸襟，</p>
      <p>則感恩之心盡在不言中。</p>
      <p>——静思语</p>
    </div>
    </div>
    <div v-if="userId == '2'" class="clsNews">
      <p class="alkdsj">每日一叮咛 | 爱的能量 善的祥和</p>
      <p class="okadsk">Tzu Chi 慈濟慈善事業基金會 2023-05-20 17:14 发表于台北</p>
      <div class="oiash">
        <img src="../assets/img/02.jpg" alt="">
      </div>
      <p class="jasdkj">爱的能量 善的祥和</p>
      <div class="aisudh">
      <p>時間、時間，</p>
      <p>每一週第一句話，</p>
      <p>少不了帶上時間。</p>
      <p>時間可以成就一切；</p>
      <p>時間也可以磨損一切，</p>
      <p>看如何顧好一念心。</p>
      <p>一念間是禍還是福？</p>
      <p>把握時間多多造福，</p>
      <p>看見人間愛的能量，</p>
      <p>見證社會善的祥和。</p>
      <p>——静思语</p>
    </div>
    </div>
    <div v-if="userId == '3'" class="clsNews">
      <p class="alkdsj">每日一叮嚀 | 身心健康</p>
      <p class="okadsk">Tzu Chi 慈濟慈善事業基金會 2023-05-18 15:42 發表於台北</p>
      <div class="oiash">
        <img src="../assets/img/03.jpg" alt="">
      </div>
      <p class="jasdkj">真正的感恩</p>
      <div class="aisudh">
      <p>飲食八分飽，生理調和，</p>
      <p>身體健康；存二分助人，</p>
      <p>發揚愛心，心靈健康。</p>
      <p>——静思语</p>
    </div>
    </div>
    <Bootm/>
  </div>
</template>

<script>
import Bootm from '@/views/bootm.vue';
export default {
 components:{ Bootm },
 data() {
  return {
    userId:''
  }
 },
 created() {
   this.userId = this.$route.query.user
 },
 methods: {
  onReturn () {
      this.$router.push({ name: 'Home1' })
    },
 }
}
</script>

<style lang="less" scoped>
.titlereturn {
  width: 100%;
  // height: 56px;
  background: #fff;
  position: relative;
  text-align: center;
  line-height: 56px;
  margin-bottom: 12px;
  .clsLeft {
  font-size: 22px;
  line-height: 56px;
  position: absolute;
  left: 0;
  color: #6f6e6e;
}
.titleName {
  font-weight: 900;
  font-size: 16px;
  color: #ff976a;
}
}
  .alkdsj {
    font-size: 22px;
    line-height: 1.4;
    margin-bottom: 14px;
    margin: 0 22px 14px 22px;
  }
  .okadsk {
    font-size: 14px;
    color: #ccc;
    margin: 0 22px 14px 22px;
  }
  .oiash {
    width: 90%;
    margin: 22px auto;
    img {
      width: 100%;
    }
  }
  .jasdkj {
    color: rgb(59, 149, 115);
    font-size: 17px;
    font-family: Optima-Regular, PingFangTC-light;
    padding: 0px 15px;
    line-height: 2;
    letter-spacing: 2px;
    text-align: center;
  }
  .aisudh {
    color: rgb(62, 62, 62);
    text-align: center;
    line-height: 2.5;
    letter-spacing: 1px;
    padding: 0px 15px;
    width: 90%;
    margin: 22px auto;
    vertical-align: top;
    background-color: rgb(251, 245, 236);
    align-self: flex-start;
    flex: 0 0 auto;
    box-sizing: bord;
  }
</style>
