<template>
  <div>
    <div class="titlereturn">
      <van-icon style="cursor:pointer" @click="onReturn" class="clsLeft" name="arrow-left" >返回</van-icon>
      <span v-if="senId === '1'" class="titleName">醫療援助</span>
      <span v-if="senId === '2'" class="titleName">助學援助</span>
      <span v-if="senId === '3'" class="titleName">長期救助</span>
      <span v-if="senId === '4'" class="titleName">寒冬送暖</span>
      <div>
      <van-notice-bar
       v-if="senId === '1'"
        left-icon="volume-o"
        text='秦**領取醫療金11400元。簡**領取醫療金10100元。張**領取醫療金8960元。蔡**領取醫療金18300元。王**領取醫療金9880元。 陸**領取醫療金10400元。  劉**領取醫療金16000元。趙**領取醫療金11500元。洪**領取醫療金12000元。陳**領取醫療金9880元。黃**領取醫療金10400元。  林**領取醫療金16000元。李**領取醫療金11500元。張**領取醫療金12000元。王**領取醫療金11900元王**領取醫療金11900元。蔡**領取醫療金10800元。秦**領取醫療金16100元。陳**領取醫療金10500元。 林**領取醫療金9600元。 黃**領取醫療金11400元。陳**領取醫療金10100元。張**領取醫療金8960元。蔡**領取醫療金18300元。蔡**領取醫療金10800元。王**領取醫療金16100元。 陳**領取醫療金10500元。 于**領取醫療金9600元。
               '
      />
      <van-notice-bar
       v-if="senId === '2'"
        left-icon="volume-o"
        text='黃**領取助學金10400元。  林**領取助學金16000元。李**領取助學金11500元。張**領取助學金12000元。王**領取助學金11900元。蔡**領取助學金10800元。秦**領取助學金16100元。陳**領取助學金10500元。 秦**領取助學金11400元。簡**領取助學金10100元。張**領取助學金8960元。蔡**領取助學金18300元。王**領取助學金9880元。 陸**領取助學金10400元。  劉**領取助學金16000元。趙**領取助學金11500元。洪**領取助學金12000元。陳**領取助學金9880元。林**領取助學金9600元。 黃**領取助學金11400元。陳**領取助學金10100元。張**領取助學金8960元。蔡**領取助學金18300元。。蔡**領取助學金10800元。王**領取助學金16100元。 陳**領取助學金10500元。 于**領取助學金9600元。'
      />
     </div>
    </div>
    <!-- 醫療内容 -->
    <div v-if="senId === '1'" class="clsNews">
       <!-- 轮播头条 -->
     <div class="clsNeir">
      <div class="clsyl">
       <img src="../assets/img/yl01.png" alt="">
      </div>
      <hr>
      <div class="clsyl">
       <img src="../assets/img/yl02.jpg" alt="">
      </div>
      <h4>項目背景及社會需求</h4>
      <p>&nbsp;&nbsp;&nbsp;2030《健康中國規劃綱要》指出：“全民健康是建設健康中國的根本目的，推進健康中國建設，要堅持預防爲主，推行健康文明的生活方式。突出解決好婦女兒童、老年人、殘疾人、流動人口、低收入人羣等重點人羣的健康問題”。</p>
      <p>&nbsp;&nbsp;&nbsp;我會在開展個案長期濟助、偏鄉精準脫貧和鄉村振興，以及城市環衛、外來務工子弟師生關懷等過程中瞭解到，偏鄉羣衆因交通不便、當地醫療條件有限、城市低收入人羣無暇做好自我健康照顧等原因，沒有定期體檢，有的疾病患者沒有在早期發現和治療，導致變成大病，進而進一步拖累家境經濟狀況。故積極支持偏鄉公共衛生事業發展，聯合相關醫療機構開展義診義檢、健康觀念宣導，並資助困難家庭及時就醫，爲病患帶來人生希望，也緩解患者家庭的經濟和生活壓力。</p>
      <hr>
      <h4>項目目標</h4>
      <p>&nbsp;&nbsp;&nbsp;1、及時進行個案救助。對遭受疾病困擾或意外傷害的弱勢家庭，經審慎評估後適當予以醫療費用等方面的救助，使其能及時就醫，改善或恢復身體健康，防止“因病而貧、因病返貧”。</p>
      <p>&nbsp;&nbsp;&nbsp;2、支持公共衛生事業發展。通過援建或資助提升醫療基礎設施、無償獻血倡導、支持特定疾病的康復與照護知識普及、促進醫患和諧等方式，支持地區公共衛生機構更好地開展醫療衛生服務。</p>
      <p>&nbsp;&nbsp;&nbsp;3、促進民衆健康提升。通過聯合相關醫療機構和專業人士開展義診義檢、健康知識宣傳、健康生活方式普及、健康諮詢活動，藉由早預防、早檢查、早發現、早治療，提升民衆健康觀念、培養健康習慣，預防重病、大病的發生，以減輕國家、社會和家庭個人的負擔。</p>
      <hr>
      <h4>項目執行情況</h4>
    </div>
    <van-grid class="tupian1" :border="false" direction="horizontal" :column-num="3">
      <van-grid-item v-for="(item , id) in ylData" :key="id" text="文字" >
          <img :src="item.img" alt="">
      </van-grid-item>
    </van-grid>
    <van-collapse v-model="etonActi">
      <van-collapse-item v-for="(item,id) in etonNmae" :key="id" :title="item.name" :name="item.id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.msg}}</van-collapse-item>
    </van-collapse>
    </div>
    <!-- 組學内容 -->
    <div v-if="senId === '2'" class="clsNews">
      <div class="clsNeir">
      <div class="clsyl">
       <img src="../assets/img/zx01.png" alt="">
      </div>
      <hr>
      <div class="clsyl">
       <img src="../assets/img/zx02.jpg" alt="">
      </div>
      <h4>項目簡介</h4>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;爲鼓勵弱勢家庭的孩子立志學習，我會結合慈善與助學，開展“新芽獎助學”項目。結合助學生的家庭情況與學校的學費、在校生活費等，制定適切的補助計劃並定期發放獎助學金，協助其安心完成學業。同時通過家訪、校訪、聯誼等方式，持續陪伴關懷助學生及其家庭，幫助助學生樹立信心，感受溫暖，提升溝通交流能力。通過公益實踐、典範學習，培養孩子們正確的人生觀與價值觀，引導其自強不息，改變自己和家庭的未來，長大後成爲懂得回饋社會國家的優秀人才。
      </p>
      <hr>
      <h4>項目目標</h4>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;1、面對困難家庭學生開展獎助學，主要補助其學費、在校生活費等，讓清寒學子得以安心完成學業。</p>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;;2、以愛心陪伴，以耐心聆聽，鼓勵孩子奮發向學，協助面對生活、學業、家庭和個人成長上的困難。通過慈善公益活動培養愛心，通過親子活動學習表達對家人的關心、孝心和感恩心，通過團體活動樹立信心和友愛互助精神，陪伴孩子逐漸成長爲品德、能力兼優的社會良才。
      </p>
      <hr>
      <div style="text-align: center;">
        <h4>執行範圍</h4>
        <p>全國</p>
        <hr>
        <h4>資助對象</h4>
        <p>家境困難的小學、初中、高中、職專、大學在校生。</p>
        <hr>
        <h4>合作單位</h4>
        <p>各地民政局、教育局、大中小學、職業學校等。</p>
        <hr>
      </div>
      <h4>項目成果</h4>
          <p>
          &nbsp;&nbsp;&nbsp;&nbsp;從2008年至2022年，本項目每年持續開展，已資助的助學生逾81000人次。其中有許多已經成爲優秀的老師、工程師、醫生等人才，在社會的各個崗位上發揮才幹，有部分也在工作之餘熱心公益，成爲助人的志願者，爲學弟學妹們做出了良好的榜樣。
          </p>
      <van-grid class="tupian1" :border="false" direction="horizontal" :column-num="3">
      <van-grid-item v-for="(item , id) in zxData" :key="id" text="文字" >
          <img :src="item.img" alt="">
      </van-grid-item>
    </van-grid>
    </div>
    </div>
    <!-- 長期濟助 -->
    <div v-if="senId === '3'" class="clsNews">
       <!-- 轮播头条 -->
     <div class="clsNeir">
      <div class="clsyl">
       <img src="../assets/img/cq01.png" alt="">
      </div>
      <hr>
      <div class="clsyl">
       <img src="../assets/img/cqjz01.jpg" alt="">
      </div>
      <h4>項目背景及社會需求</h4>
      <p>&nbsp;&nbsp;&nbsp;社會救助事關困難羣衆基本生活和衣食冷暖，關係民生、連着民心，是社會建設的兜底性、基礎性工作。2020年4月22日，《關於改革完善社會救助制度的意見》，對各項社會救助做出了一系列新規定、新要求，爲推動新時代社會救助改革和發展提供了綱領性指引。其中特別提到“促進社會力量參與”，“鼓勵支持自然人、法人及其他組織自願開展慈善幫扶活動，動員引導慈善組織加大社會救助方面支出”。</p>
      <p>
        &nbsp;&nbsp;&nbsp;多年來，慈濟一直以無私大愛爲信念，以提倡人人行善、促進社會和諧爲目標,強調敦親睦鄰、守望相助。在實際工作中，也常接到個案提報：有的家庭因病、因殘、因意外等導致經濟困頓；有的因單親或隔代撫養兒童，難以獨自支持；有的因家庭收入不高，孩子就學面臨壓力；有的因家庭關係或個人認知出現嚴重問題，需要關懷輔導......他們的困難，不僅在於缺乏足夠的收入以確保有品質的生活，也包括無法及時取得足夠的教育和醫療等資源。對此，慈濟針對個案家庭開展有針對性的訪視與關懷扶助，以幫助其走出個人困境，也更好地提升能力、鏈接資源、融入社會。
      </p>
      <p>&nbsp;&nbsp;&nbsp;而隨着社會的快速發展與轉型，慈善工作所關注的人羣也在不斷擴大。針對失獨家庭、弱勢長者、街頭流浪人員、農村困境兒童、外來務工人員隨遷子女等羣體，慈濟也因應需要，針對不同羣體開展專案,以進一步改善他們的生存狀況，努力使人人都能共享社會進步的成果。</p>
      <hr>
      <h4>項目目標</h4>
      <p>&nbsp;&nbsp;&nbsp;1、對鰥、寡、孤、獨、廢疾者,及家中一時困難的家庭,依實地走訪中看見的需求，結合政府及社會愛心資源，直接、重點、務實、及時提供精準且必要的照顧。</p>
      <p>&nbsp;&nbsp;&nbsp;2、在改善弱勢家庭生活困境的同時，更以“扶志”爲目標，激發潛能，增強自信，提升其戰勝困難的能力，以改善自己和家庭的不良境遇，並更好地融入社會。</p>
      <p>&nbsp;&nbsp;&nbsp;3、通過見苦知福,啓發人人心中的愛與善，增強社會的誠信與友愛、促進社會和諧發展。</p>
      <hr>
      <h4>項目執行情況</h4>
    </div>
    <van-grid class="tupian1" :border="false" direction="horizontal" :column-num="3">
      <van-grid-item v-for="(item , id) in cqData" :key="id" text="文字" >
          <img :src="item.img" alt="">
      </van-grid-item>
    </van-grid>
    <hr>
    <van-collapse v-model="activeNames">
      <van-collapse-item title-class="skj" v-for="(item,id) in collapseNmae" :key="id" :title="item.name" :name="item.id">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{item.msg}}</van-collapse-item>
    </van-collapse>
    </div>
    <!-- 寒冬送暖 -->
    <div v-if="senId === '4'" class="clsNews">
      <div class="clsNeir">
      <div class="clsyl">
       <img src="../assets/img/hd01.png" alt="">
      </div>
      <hr>
      <div class="clsyl">
       <img src="../assets/img/hdsn01.jpg" alt="">
      </div>
      <h4>項目簡介</h4>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;多年來，我會在每年寒冬季節開展“寒冬送暖”，爲困難羣衆送去過冬物資,令其歡喜過年。隨着我國脫貧攻堅戰的穩步推進和人民生活水平日漸提高，過去鄉親們愁溫飽愁年關的窘境已不復多見，但是社會上仍然存在着部分的相對困難羣衆、受災民衆，需要社會的持續關愛與扶助。</p>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;爲了讓這部分人羣有更多的獲得感、幸福感,切實感受到社會的溫暖與關懷,我會與各地政府相關部門合作，在每年寒冬時節，按其生活所需，爲他們送上新衣、新被、生活日用品及一些年節用品,讓他們度過歡樂祥和的新春佳節；並以走親戚方式，傳遞尊重與溫暖，從走進門坎到走進心坎，激發受助者的信心和自強自立的勇氣。同時，邀請當地幹部、鄉親、志願者、愛心人士共同參與，帶動守望相助的社會良善之風，以“感恩、尊重、愛”，共創和諧社會。</p>
      <hr>
      <h4>項目目標</h4>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;秉持直接、重點、尊重、務實、及時的原則，在每年11月下旬至次年春節前按計劃完成各省市的寒冬送暖關愛行動，送上優質糧油、衣被、生活用品等豐富的物資，讓受助鄉親民衆安心過冬過年，同時感受到來自社會的關愛。</p>
      <hr>
      <div style="text-align: center;">
        <h4>執行範圍</h4>
        <p>全國</p>
        <hr>
        <h4>關懷對象</h4>
        <p>各機構提報的特困戶、低保戶、五保戶、計生特殊家庭、助學生、環衛工人、敬老院長者、受災戶，以及慈濟長期濟助的關懷戶等。</p>
        <hr>
        <h4>合作單位</h4>
        <p>各地人民政府、民政局、慈善總會、婦女聯合會、殘疾人聯合會、城管局、扶貧辦、鄉村振興辦公室、衛生健康局、學校、敬老院、街道辦事處等。</p>
        <hr>
      </div>
      <h4>項目成果</h4>
          <p>
          &nbsp;&nbsp;&nbsp;&nbsp;從2008年基金會成立迄今，項目每年持續開展，關懷足跡遍佈28省、自治區、直轄市，14年間已嘉惠逾76萬戶，145萬人。其中，2019年度：共嘉惠33,595戶,70,950人；2020年度：共嘉惠42,798戶,72,085人；2021年度：共嘉惠33,997戶,57,338人。
          </p>
          <p>&nbsp;&nbsp;&nbsp;&nbsp;寒冬送暖爲困難家庭的冬季增添了許多暖意與力量。在項目執行過程中，我會亦邀請當地愛心人士與志願者共同參與，帶動守望相助的良善之風。更與許多地區結下了深厚情誼，進而延伸至個案濟助、獎助學等慈善項目的長期合作。</p>
      <van-grid class="tupian1" :border="false" direction="horizontal" :column-num="3">
      <van-grid-item v-for="(item , id) in hdData" :key="id" text="文字" >
          <img :src="item.img" alt="">
      </van-grid-item>
    </van-grid>
    </div>
    </div>
    <Bootm/>
  </div>
</template>

<script>
import Bootm from '@/views/bootm.vue';
export default {
  components:{ Bootm },
  data() {
    return {
      senId:'',
       ylData:[
        {img:require("../assets/img/yl03.jpg")},
        {img:require("../assets/img/yl04.jpg")},
        {img:require("../assets/img/yl05.jpg")}
       ],
       zxData:[
        {img:require("../assets/img/zx03.jpg")},
        {img:require("../assets/img/zx04.jpg")},
        {img:require("../assets/img/zx05.jpg")}
       ],
       cqData:[
        {img:require("../assets/img/cqjz02.jpg")},
        {img:require("../assets/img/cqjz03.jpg")},
        {img:require("../assets/img/cqjz04.jpg")}
       ],
       hdData:[
        {img:require("../assets/img/hdsn02.jpg")},
        {img:require("../assets/img/hdsn03.jpg")},
        {img:require("../assets/img/hdsn04.jpg")}
       ],
       etonActi:[1],
       etonNmae: [
        {id:1,name:'1.援建偏鄉衛生院 ',msg:'爲助力精準脫貧和鄉村振興，援建喜德縣冕山鎮中心衛生院（已投入使用）、中江縣富興鎮衛生院（在建），以改善當地居民就醫條件，提升健康保障。喜德縣人民政府在感謝信中表示：冕山中心衛生院的建成,極大改善了冕山全鎮羣衆的醫療環境。衛生院大樓集全科、婦科、兒科、中醫科等於一體,並有寬敞舒適的候診區，當地羣衆可以就近看診、產婦可以就近生產、兒童可以就近打疫苗。2021年衛生院門診診療量比2020年增加28.5%,住院人次增加19.6%。'},
        {id:2,name:'2.開展個案醫療救助  ',msg:'爲鞏固脫貧攻堅成果，防止“因病而貧、因病返貧”，我會對遭受疾病困擾或意外傷害的弱勢家庭，經審慎評估後適當予以醫療費用等方面的救助，使其能及時就醫，改善或恢復身體健康。2019-2021年，醫療個案補助逾300人次，幫助155位患者，大部分的受助者健康狀況得以較大改善，生命品質得以提升，家庭關係也有顯著改觀。例如，臺東地區的孩童鄒*寶，患有罕見的先天性脛骨假關節，治療費用預計達數萬元，家庭無力承擔。志工陪伴，協助家長尋求專業解決方案，最終決定截肢並裝假肢，慈濟資助相關醫療費用。2021年，患兒已完成手術，迎來新生。至今，志工仍定期和家長一起，帶着駿寶到醫院複診、復健。'},
        {id:3,name:'3.普及健康生活理念 ',msg:'爲提升兒童及家庭的健康素養，依託各地健康故事屋空間，共舉辦了280期親子共讀活動，共有922個家庭參與了健康繪本親子書袋的交換活動，除了引導家長陪同孩子共同學習健康常識、認識身體、瞭解情緒外，也幫助兒童從小養成12個健康好習慣。同時，與北京陸道培醫院、北京市兒童醫院、北京市兒研所等醫院合作，通過提供繪本資源、定期舉辦親子共讀等活動，增進了親子互動，讓患兒通過繪本更好地瞭解疾病、瞭解生命，更堅強地面對治療過程中的考驗，取得良好成效。'},
        {id:4,name:'4.加強對特定疾病患者及其家庭的關懷與支持 ',msg:'我會與上海市醫學會罕見病專科分會、復旦大學附屬兒科醫院DMD多學科團隊、上海集愛肌病關愛中心聯合組織“大愛暖冰—DMD關懷行動”“大愛暖冰—DMD關懷行動”，2021年3共舉辦十次講座，全年共計128人次家庭參與。除了分享DMD患者家庭所關心的專業醫療知識，也請患者家庭分享心路成長曆程、讓親子一起玩遊戲、做手工、感受大自然的美好，關注環境保護等，幫助家長緩解身心壓力，逐漸形成自助互助的團體。'},
       ],
       activeNames: [1],
       collapseNmae: [
        {id:1,name:'1.个案关怀参与社会救助不间断 ',msg:'以感恩、尊重、愛爲核心精神，秉持全心、全人、全家、全程之關懷理念，與直接、重點、尊重、及時、務實之服務原則，依困難家庭的實際需求，予以個案長期濟助與關懷。其中，經評估以個案方式關懷扶助的家庭或個人，包括但不限於低保戶、五保戶、困境兒童，家庭經濟較困難之重病患者、獨居長者等，以及受重大意外事件影響的個人及家庭。2017年至2021年的五年間，關懷個案逾14,600戶次，持續每個月前往個案家中訪視。其中，2021年共在24個省/自治區、4個直轄市長期關懷近3,800戶家庭。'},
        {id:2,name:'2.創新項目助力弱勢羣體促和諧 ',msg:'針對失獨家庭、弱勢長者、街頭流浪人員、農村困境兒童、外務工人員隨遷子女、殘障人士等羣體，慈濟在政府相關部門或單位的支持下，開展“有愛不孤獨”“耆樂融融”“關愛街友”“愛童行 樂成長”“紅豆杉的愛”“我是你的眼”等項目。五年來，累計服務人次達42,883人次。'},
        {id:3,name:'3.務實評估滿足差異化救助需求 ',msg:'除了給予經濟補助、房屋修繕、環境清掃等幫助，志工也對受助家庭給予正向鼓勵，長期溫暖陪伴，幫助其積極面對人生的坎坷與困難。很多家庭走出身心困境，迎接生命的陽光而幸福生活。'},
        {id:4,name:'4.用心陪伴鼓勵受助者自助又助人 ',msg:'更有曾經的受助者，走出自己的困境後成爲一名樂於助人的志願者。許多受助者回饋，得到社會的愛心幫助，自己很受感動和鼓舞，更有力量面對生活的困難。自己也要像慈濟志工一樣，盡己所能付出愛心，幫助有困難的人。'},
        {id:5,name:'5.見苦知福搭建助人者自我成長平臺 ',msg:'  透过参与个案访视、弱势群体关怀，志工有机会见苦知福，进而乐于奉献爱心，齐为社会和谐贡献己力，成为弘扬社会正能量、积极参与精神文明建设的典范。 '}
       ]
    }
  },
  created () {
    this.senId = this.$route.query.user
  },
    methods: {
      onReturn() {
        this.$router.push({name:"Home1"})
      }
    }
}
</script>

<style lang="less" scoped>
  .titlereturn {
    width: 100%;
    // height: 56px;
    background: #fff;
    position: relative;
    text-align: center;
    line-height: 56px;
    margin-bottom: 12px;
    .clsLeft {
    font-size: 22px;
    line-height: 56px;
    position: absolute;
    left: 0;
    color: #6f6e6e;
  }
  .titleName {
    font-weight: 900;
    font-size: 16px;
    color: #ff976a;
  }
  }
  .clsNeir {
    padding: 10px;
    hr {
      background-color: #d7d7d7;
      height: 1px;
      margin: 16px 0;
    }
    h4 {
      text-align: center;
      color: #000;
      font-weight: 700;
      margin: 30px 0;
      font-size: 20px;
    }

    p {
      line-height: 28px;
      margin: 0 0 10px;
      border-radius: 5px;
      font-size: 18px;
      color: #464444;
      padding: 0 10px;
    }
  }
  .clsyl {
    width: 90%;
    margin: 0 auto;
     img {
      width: 100%;
     }
  }
  .tupian1 {
    img {
      width: 100%;
    }
  }
  .xlimg {
    h5 {
      margin: 25px 0 10px 0;
      color: #517992;
      font-size: 18px;
      font-weight: 600;
    }
    p {
      line-height: 28px;
      margin: 0 0 10px;
      border-radius: 5px;
      font-size: 18px;
      color: #464444;
      padding: 0 10px;
    }
  }
  @media screen and (max-width: 450px) {

  }
  @media screen and (min-width: 450px) and (max-width: 800px) {
    .clsNews {
      width: 50%;
      margin: 0 auto;
    }
  }
  @media screen and (min-width: 800px) {
    .clsNews {
      width: 50%;
      margin: 0 auto;
    }
  }
</style>
